
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$timeSheetManagement-primary: mat.define-palette(mat.$indigo-palette);
$timeSheetManagement-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$timeSheetManagement-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$timeSheetManagement-theme: mat.define-light-theme((
  color: (
    primary: $timeSheetManagement-primary,
    accent: $timeSheetManagement-accent,
    warn: $timeSheetManagement-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($timeSheetManagement-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&display=swap");
@import "bootstrap/scss/bootstrap";
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "~@ng-select/ng-select/themes/default.theme.css";
// variables
$font-family: "Space Grotesk", sans-serif;
@import "./assets/scss/variable.scss";

// $font-family-secondary: 'Inter', sans-serif;
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}



body {
  margin: 0;
  // font-family: "Space Grotesk", sans-serif !important;
  font-family: "Space Grotesk", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Grotesk", sans-serif;
}
button {
  font-family: "Space Grotesk", sans-serif;
}
input,
textarea,
select {
  font-family: "Space Grotesk", sans-serif;
}

// //timeshhet add modal global styles

.modal.show .modal-right {
  height: 100% !important;
}

.modal-content {
  height: 100% !important;
}
.charcter-count {
  position: absolute;
  right: 13px;
  bottom: 2px;
  font-size: 12px;
  color: $gray_text;
}

.modal-right {
  min-width: 60%;
  margin-right: initial !important;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  position: fixed;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.fade {
  transition: opacity 0.2s linear !important;
}

.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.fade .modal-right {
  transform: translate(2px, 0) !important;
  margin-top: 0px;
  right: -576px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.fade.show .modal-right {
  right: 0;
}

//ngx bootstrap datepicker styles

.theme-green .bs-datepicker-body table td.week span {
  display: none;
}

.theme-green .bs-datepicker-head {
  background-color: $primary_color;
}
.bs-datepicker-head button.current {
  font-size: 14px;
}
//ngb bootstrap datepicker styles
.ngb-dp-header {
  background: white !important;
}

.ngb-dp-weekdays {
  background: white !important;
}

.ngb-dp-weekday {
  color: #059669 !important;
  font-style: normal !important;
}

.ngb-dp-day {
  font-size: 12px !important;
}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #059669 !important;
}
ngb-datepicker-navigation-select > .form-select {
  flex: 1 1 auto;
  padding: 0 0.5rem;
  height: 1.85rem;
}

.ngb-dp-navigation-chevron {
  font-size: 12px;
}

.outside {
  font-size: 12px !important;
}

.modal-mysize {
  max-width: 200px !important;
}
// addmodal styles

.modal.show .addtask-modalright {
  height: 100% !important;
  overflow-y: scroll;
}

.addtask-modalright {
  min-width: 45%;
  position: fixed;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* For medium screens */
@media (max-width: 768px) {
  .addtask-modalright {
    min-width: 35%;
  }
}

/* For small screens */
@media (max-width: 480px) {
  .addtask-modalright {
    min-width: 100%;
    padding: 0;
    margin: 0;
  }
}

/* For ultra-small screens (below 370px) */
@media (max-width: 370px) {
  .addtask-modalright {
    min-width: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px; /* Adjust font size for better readability */
    height: auto;    /* Set height to auto if needed for vertical scroll */
  }
}

.modal.fade .addtask-modalright {
  transform: translate(2px, 0) !important;
  margin-top: 0px;
  right: -576px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.fade.show .addtask-modalright {
  right: 0;
}



.empty {
  text-align: center;
  color: $gray_text;
  img {
    width: 200px;
  }
  .nodataText {
    font-size: 14px;
    color: $gray_text;
    text-align: center;
    margin-top: 12px;
  }
}

.icon-container {
  color: $primary_color;
  cursor: pointer;
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 2;
  &:hover {
    color: lighten($color: $primary_color, $amount: 10%);
  }
}
.skeleton-loader-margin {
  margin-bottom: 40px;
}
.table-border {
  border-radius: 5px;
}

::ng-deep .NgxEditor {
 height: 150px;
  overflow-y: scroll;
}

  .NgxEditor__Content {
    min-height: auto; /* make all of the editor area clickable */
  }

      
/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;height: auto; }

.app-container:has(app-star-performer-display,app-nomination-form,app-add-edit-profile){
  min-width: 460px;
  max-width: 800px;
  margin-inline: auto;
}

.star-performer-banner-display{
  .cdk-overlay-container{
    z-index: 999999999;
  }
}