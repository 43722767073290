// $primary_color: #4a70fe;
// $primary_color: #428ec5;
$primary_color: #059669;

// $primary_color: #6770b9;
$sub_primary_color: #4a70ff;
$secondary_color: #e8efff;
// $sub_secondary_color: #49c559;
$sub_secondary_color: #69cf5e;
//  gradient color
$primary-gradient: linear-gradient(to right, #796ddf7e, #3498db, #1470ad);
// $secondary-color: #16a085;
// #428ec5
$black: #0000;
$black-light: #030f26;

$gray_color: #e2e4ed;
$gray_light: #f5f6fa;

// using for dropdown text color//
$gray_black: #7d8996;

$gray_lightblack: #a3b1bf;

// using for navbar text color a tag//
$gray_text: #7e8c99;

$white: #ffffff;

$fb_color: #2e64c1;
$tw_color: #8dc4ea;
$gp_color: #ff2626;
$tbbg_color: #efefef;
// background-color: #d4d5d5;
$layp0: rgba($primary_color, 0);
$layp1: rgba($primary_color, 0.1);
$layp2: rgba($primary_color, 0.2);
$layp3: rgba($primary_color, 0.3);
$layp4: rgba($primary_color, 0.4);
$layp5: rgba($primary_color, 0.5);
$layp6: rgba($primary_color, 0.6);
$layp7: rgba($primary_color, 0.7);
$layp8: rgba($primary_color, 0.8);
$layp9: rgba($primary_color, 0.9);
$layp10: rgba($primary_color, 1);

$laysp0: rgba($sub_primary_color, 0);
$laysp1: rgba($sub_primary_color, 0.1);
$laysp2: rgba($sub_primary_color, 0.2);
$laysp3: rgba($sub_primary_color, 0.3);
$laysp4: rgba($sub_primary_color, 0.4);
$laysp5: rgba($sub_primary_color, 0.5);
$laysp6: rgba($sub_primary_color, 0.6);
$laysp7: rgba($sub_primary_color, 0.7);
$laysp8: rgba($sub_primary_color, 0.8);
$laysp9: rgba($sub_primary_color, 0.9);
$laysp10: rgba($sub_primary_color, 1);

$layb0: rgba($black, 0);
$layb1: rgba($black, 0.1);
$layb2: rgba($black, 0.2);
$layb3: rgba($black, 0.3);
$layb4: rgba($black, 0.4);
$layb5: rgba($black, 0.5);
$layb6: rgba($black, 0.6);
$layb7: rgba($black, 0.7);
$layb8: rgba($black, 0.8);
$layb9: rgba($black, 0.9);
$layb10: rgba($black, 1);

$layw0: rgba($white, 0);
$layw1: rgba($white, 0.1);
$layw2: rgba($white, 0.2);
$layw3: rgba($white, 0.3);
$layw4: rgba($white, 0.4);
$layw5: rgba($white, 0.5);
$layw6: rgba($white, 0.6);
$layw7: rgba($white, 0.7);
$layw8: rgba($white, 0.8);
$layw9: rgba($white, 0.9);
$layw10: rgba($white, 1);

//flat colors
$ftu_color: #1bb899;
$fgs_color: #1ea286;
$fem_color: #49b96e;
$fne_color: #23ae5e;
$fpr_color: #3596d3;
$fbh_color: #2581bc;
$fam_color: #965ba4;
$fwi_color: #894b9d;
$fwa_color: #34495d;
$fmb_color: #2c3e50;
$fsf_color: #efc419;
$fog_color: #f39d20;
$fca_color: #e67d25;
$fpu_color: #d35728;
$fal_color: #e74c3b;
$fpo_color: #c13b2b;
$fcl_color: #fcfcfc;
$fsi_color: #bdc1c7;
$fco_color: #93a4a5;
$fas_color: #7f8d8d;

// media query
// $resize: "screen and (max-width: 377px)";
$tablet: "screen and (max-width: 1024px) and (min-width: 768px)";
$mobile: "screen and (max-width: 640px)";
$iphoneplus: "screen and (min-width: 412px) and (max-width: 736px) and (max-device-pixel-ratio: 3)";
$smallMobile: "screen and (max-width: 320px)";
$otherMobile: "screen and (max-width: 360px)";
$largescreen: "screen and (min-width: 1920px)";
$hdmonitor: "screen and (min-width: 1600px)";

$mq5b: "screen and (max-width: 565px)";
$mq7b: "screen and (max-width: 767px)";
$mq9b: "screen and (max-width: 991px)";
$mq12b: "screen and (max-width: 1199px)";

$mq5a: "screen and (min-width: 566px)";
$mq7a: "screen and (min-width: 768px)";
$mq9a: "screen and (min-width: 992px)";
$mq12a: "screen and (min-width: 1200px)";

//variables ends here
